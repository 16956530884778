<!-- Informes ATLAS -->

<template>
  <div class="atlas-report" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader">
          </baseHeader>
        </div>


        <!-- Botonera -->
        <div class="pt-2 pl-2">
          <baseBtraExtra class="conflex"
            :permExtra="permExtra"
            :modulo="btExtCfg"
            @onEvent="execAccion">
          </baseBtraExtra>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:900px">
          <div class="conflex">
            <div class="columna" style="width:32%">

              <!-- Menú -->
              <div class="cab">Tipos de Informes</div>
              <v-sheet v-bind="$cfg.styles.marco">
                <v-treeview
                  ref=""
                  :items="recordsRead[0]"
                  item-key="d"
                  item-text="n"
                  item-children="c"
                  rounded
                  activatable
                  dense
                  open-on-click
                  return-object
                  @update:active="changeInf">
                </v-treeview>
              </v-sheet>

            </div>

            <div class="columna" style="width:66%;margin-left:10px">
              <div class="cab">Condiciones</div>

              <v-sheet v-bind="$cfg.styles.marco">

                <div style="display:flex;width:40%">
                  <v-select
                      v-bind="$select"
                      v-model="ct.ord[2]"
                      :label="ct.ord[1]"
                      :items="ord"
                      item-value="d"
                      item-text="n">
                  </v-select>

                </div>

                <div style="display:flex">
                    <v-select
                        style="width:40%"
                        v-bind="$select"
                        v-model="ct.nivel[2]"
                        :label="ct.nivel[1]"
                        :items="recordsRead[2]"
                        item-value="d"
                        item-text="n">
                    </v-select>

                    <ctrlF style="width:60%" :ct="ct.ori"></ctrlF>

                </div>

              </v-sheet>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>

  import { mixR } from "@/mixins/mixR.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";

  export default {
    mixins: [mixR],
    components: { baseHeader, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "atlasR",
          titulo:"·Informes - ATLAS",
          name:"atlasR",
          mView:'',
          pView:[]
        },

        ord: [],

      };
    },


    methods: {
        iniDataParticular() {
            console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

            this.btExtCfg.btnsAccion= [
            { accion:'verPdf', btn:"pdf" },
            { accion:'verExcel', btn:"excel" }

            ];

            // coordinadores de servicio
            // this.itemsZonas= this.$store.state.datos_iniciales.zonas.slice();
            // this.itemsZonas.unshift({id:'0',name:'( Selecciona )'});

        },

        stRecordsGetFin() {
            //seleccionamos omision el informe
            let inf=this.recordsRead[0];
            this.changeInf(inf);

        },



      // muestro informe en pdf
      verPdf() {
        this.showReport("pdf");
      },

      // muestro informe en excel
      verExcel() {
        this.showReport("xls");
      },


      // muestro informe
      async showReport(tip) {
        // compruebo si hay seleccionado un Informe
        if (!this.ct.inf[2]) {
          this.$root.$alert.open('Debes seleccionar un informe', 'error');
          return;
        }

        this.report(tip);
      }

    }
  };
</script>
